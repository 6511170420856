import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { MediaModel } from '@libs/gc-common/lib/api/models/media';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { MomentService } from '@libs/gc-common/lib/services/moment/moment.service';
import HLS from 'hls.js';

@Component({
  selector: 'mip-feed-item',
  templateUrl: './feed-item.component.html',
  styleUrls: ['./feed-item.component.scss']
})
export class FeedItemComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('videoContainer') videoContainer: ElementRef;

  @Input() postModel: PostModel = null;
  @Input() baseRoute: Array<string> | string | boolean = null;
  @Input() linkTarget: '_blank' | '_self' | '_parent' | '_top' = '_self';
  @Input() customParams: object = null;
  @Input() infoOnTop = false;
  @Input() orientation = '';
  @Input() disableLink = false;
  @Input() enableTimestamp = true;
  @Input() showViews = true;
  @Input() showPlayIcon = false;
  @Input() showPlays = false;
  @Input() showThemeIcon = true;
  @Input() avatarCrowns = null;
  @Input() enableVideoPreview = false;
  @Input() height: number = null;

  @Input() showFooter = true;

  @Input() enableLastSee: boolean = true;

  @Input() showSampleLabel = false;
  @Input() isOriginalPost = false;

  @Input() showAvatar = !!this.avatarCrowns;
  @Input() avatarBorderColor: string = null;
  @Input() avatarBorderWidth = null;
  @Input() avatarSize = 40;
  @Input() avatarCrown: string = null;

  @Output() onClick = new EventEmitter();

  videoContainerEl = null;
  videoElement: HTMLVideoElement = null;
  videoElementTimeout = null;
  loadingVideoPreview = false;
  mediaModel: MediaModel = null;

  postUrl = [];
  isSafari = false;
  bgImage = '';
  assetsPath = environment.assetsPath;

  observerHelper = new ObserverHelper();

  showTimestamp = false;
  createdAt = null;

  constructor(
    private mobileDetect: MobileDetectService,
    private userService: UserService,
    private momentService: MomentService
  ) {
    this.isSafari = this.mobileDetect.isSafari();

    this.observerHelper.addSubscription(
      this.userService.onUserAuthentication().subscribe(user => {
        this.showTimestamp = user.isModerator;
      })
    );
  }

  async ngOnInit() {

    try {
      console.log('feed-item.component->ngOnInit(): this.postModel.lastSeen', this.postModel.id, this.postModel.lastSeen);
      console.log('feed-item.component->ngOnInit(): this.postModel', this.postModel.id);

      if (this.postModel) {

        this.mediaModel = this.postModel.getVideoMedia();
        console.log('feed-item.component->ngOnInit(): this.mediaModel', this.mediaModel.id);

        if (this.baseRoute) {
          if (Array.isArray(this.baseRoute)) {
            this.postUrl = [...this.baseRoute, this.postModel.id];
          }
          else {
            this.postUrl = [this.baseRoute, this.postModel.id];
          }
        }
        else if (this.baseRoute !== false) {
          if (this.postModel.author) {
            this.postUrl = [`/@${this.postModel.author.username}`, 'p', this.postModel.id];
          }
        }

        this.bgImage = this.postModel.getPreview();
        console.log('feed-item.component->ngOnInit(): this.bgImage', this.bgImage);

      }

      const createdAt = this.momentService.single({
        date: this.postModel.createdAt,
        isEST: true,
        local: false
      });
      console.log('feed-item.component->ngOnInit() createdAt', this.postModel.createdAt, createdAt);

      this.createdAt = createdAt.format('lll');
      console.log('feed-item.component->ngOnInit() this.createdAt', this.createdAt);

      console.log('feed-item.component->ngOnInit() this.postUrl', this.postUrl);
    }
    catch (e) {
      console.log('feed-item.component->ngOnInit() ERROR', e);
    }

  }

  ngAfterViewInit() {
    if (this.videoContainer) {
      this.videoContainerEl = this.videoContainer.nativeElement;
      console.log('feed-item.component->ngAfterViewInit(): this.videoContainerEl', this.videoContainerEl);
    }
  }

  showVideoPreview() {
    console.log('feed-item.component->showVideoPreview()');

    // video preview can be off
    if (!this.videoContainerEl) {
      return;
    }

    if (this.videoElement) {
      this.videoElement.play();
      return;
    }

    if (this.videoElementTimeout) {
      clearTimeout(this.videoElementTimeout);
    }

    this.loadingVideoPreview = true;

    this.videoElementTimeout = setTimeout(() => {

      console.log('feed-item.component->showVideoPreview(): createElement->video');

      this.videoElement = document.createElement('video');
      this.videoElement.autoplay = true;
      this.videoElement.muted = true;
      this.videoElement.loop = true;

      if (this.videoElement.canPlayType(this.mediaModel.mimeType)) {
        console.log('feed-item.component->ngAfterViewInit(): VIDEO TYPE', this.mediaModel.mimeType);
        this.videoElement.src = this.mediaModel.source;
      }
      else if (HLS.isSupported()) {
        console.log('feed-item.component->ngAfterViewInit(): VIDEO HLS', this.mediaModel.mimeType);
        const hls = new HLS();
        hls.loadSource(this.mediaModel.source);
        hls.attachMedia(this.videoElement);
      }

      this.videoContainerEl.appendChild(this.videoElement);

      this.videoElement.onplay = () => {
        this.loadingVideoPreview = false;
      };

    }, 1000);

  }

  hideVideoPreview() {
    console.log('feed-item.component->hideVideoPreview()');

    if (this.videoElementTimeout) {
      clearTimeout(this.videoElementTimeout);
    }

    this.loadingVideoPreview = false;

    // video preview can be off
    if (this.videoElement) {
      this.videoElement.pause();
    }

  }

  ngOnDestroy() {
    this.observerHelper.unsubscribeAll();
  }

}
