import { Injectable } from '@angular/core';

import { environment } from '@libs/gc-common/environments/environment';
import { ContestModel } from '@libs/gc-common/lib/api/models/contest';
import { TopTenRankingModel } from '@libs/gc-common/lib/api/models/top-ten-ranking';
import { UserModel } from '@libs/gc-common/lib/api/models/user';
import { PaginationInterface } from '@libs/gc-common/lib/api/services/pagination.interface';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';
import { ResourceService } from '@libs/gc-common/lib/services/resource/resource.service';

@Injectable({
	providedIn: 'root'
})
export class WalletService {
	
	static instance: WalletService;
	
	constructor(
		private resourceService: ResourceService,
		private userService: UserService,
		private modelFactory: ModelFactoryService
	) {
		WalletService.instance = this;
	}
	
	async getUserCoupons() {
		try {
			
			if (!this.userService.isAuthenticated()) {
				throw new Error(`The user is not Authenticated`);
			}
			
			const { count } = await this.resourceService.get('api', {
				resource: 'wallet-drawing',
				path: `/wallet/drawing/my-coupons`,
				fromCache: false
			});
			
			console.log('wallet.service->getUserCoupons(): count', count);
			
			return count;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getDrawingParticipantsUsers(): Promise<Array<string>> {
		try {
			
			const { coupons } = await this.resourceService.get('api', {
				resource: 'wallet-drawing',
				path: `/wallet/drawing/cloud`,
				fromCache: false
			});
			
			console.log('wallet.service->getDrawingParticipantsUsers(): coupons', coupons);
			
			return coupons;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getNextDrawing(): Promise<{ nextDraw, prize, stream }> {
		try {
			
			const response = await this.resourceService.get('api', {
				resource: 'wallet-drawing',
				path: `/wallet/drawing/next-draw`,
				fromCache: false
			});
			
			console.log('wallet.service->getNextDrawing(): response', response);
			
			return response;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getLastWinner(fromCache = false): Promise<{ prize, date: string, user: UserModel }> {
		try {
			
			const response = await this.resourceService.get('api', {
				resource: 'wallet-drawing',
				path: `/wallet/drawing/last-winner`,
				fromCache: false
			});
			
			console.log('wallet.service->getLastWinner(): response', response);
			
			return {
				...response,
				user: this.modelFactory.userFactory.build(response.user)
			};
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getCurrentContests(): Promise<Array<ContestModel>> {
		try {
			
			const response = await this.resourceService.get('api', {
				resource: 'wallet-hashtag-contest',
				path: `/contest-view/current`,
				fromCache: false
			});
			
			console.log('hashtag.service->getCurrentContest(): response', response);
			
			return this.modelFactory.contestFactory.buildCollection(response);
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getNextContests(): Promise<Array<ContestModel>> {
		try {
			
			const response = await this.resourceService.get('api', {
				resource: 'wallet-hashtag-contest',
				path: `/contest-view/next`,
				fromCache: false
			});
			
			console.log('hashtag.service->getNextContest(): response', response);
			
			return this.modelFactory.contestFactory.buildCollection(response);
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getPreviousContest(params?: { lastId?, limit? }): Promise<{ totalCount: number, items: Array<ContestModel>, lastId: number, hasMoreItems: boolean }> {
		try {
			
			const response = await this.resourceService.get('api', {
				resource: 'wallet-hashtag-contest',
				path: `/contest-view/previous`,
				params
			});
			
			console.log('hashtag.service->getPreviousContest(): response.items', response.items);
			
			return {
				totalCount: response.totalCount,
				items: this.modelFactory.contestFactory.buildCollection(response.items),
				lastId: response.lastId,
				hasMoreItems: response.hasMoreItems
			};
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getTopTenRanking(tab: string, reference?, eventRuleName?: string): Promise<{ me: TopTenRankingModel, results: Array<TopTenRankingModel> }> {
		try {
			console.log('hashtag.service->getTopTenRanking():', { tab, reference, eventRuleName });
			
			let url = `/wallet/top10/${tab}${reference ? '/' + reference : ''}`;
			
			if (eventRuleName) {
				url = `/wallet/top10-events/${eventRuleName.toLowerCase().replace('_', '-')}/${tab}`;
			}
			
			const response = await this.resourceService.get('api', {
				resource: 'users',
				path: url,
				fromSSR: false
				// fromCache: false
			});
			
			console.log('hashtag.service->getTopTenRanking(): response', [tab], response);
			
			return {
				me: this.modelFactory.topTenRankingFactory.build(response.me),
				results: this.modelFactory.topTenRankingFactory.buildCollection(response.results)
			};
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getTopTenRankingMonths(tab: string): Promise<Array<{ reference: string }>> {
		try {
			
			const response = await this.resourceService.get('api', {
				resource: 'wallet-hashtag-contest',
				path: `/wallet/top10/${tab}/list`,
				fromCache: true
			});
			
			console.log('hashtag.service->getTopTenRankingMonths(): response', response);
			
			return response;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getEventStats(eventName: string): Promise<Array<{ 'checkin, gotcoins, referred, content' }>> {
		try {
			
			const response = await this.resourceService.get('api', {
				resource: 'wallet-events-stats',
				path: `/events/${eventName}/stats`,
				fromCache: false
			});
			
			console.log('hashtag.service->getEventStats(): response', response);
			
			return response;
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getEventLocalSignedInUsers(eventName: string, params: PaginationInterface): Promise<{ lastId, totalCount, list }> {
		try {
			
			const response = await this.resourceService.get('api', {
				resource: 'users',
				path: `/events/${eventName}/users`,
				fromSSR: false,
				params
			});
			
			console.log('hashtag.service->getEventStats(): response', response);
			
			return {
				lastId: response.lastId,
				totalCount: response.totalCount,
				list: this.modelFactory.userFactory.buildCollection(response.items) || []
			};
			
		}
		catch (e) {
			throw e;
		}
	}
	
	async getEventWinners(): Promise<{ gotchosen_experience_1, gotchosen_experience_2, gotchosen_experience_3 }> {
		try {
			
			const response = await this.resourceService.get('api', {
				resource: 'events-drawing',
				path: `/events/experience/drawing/winners`,
				fromCache: false,
				params: {
					test_mode: environment.environmentName !== 'production' ? 1 : null
				}
			});
			
			console.log('wallet.service->getLastWinner(): response', response);
			
			const winners = {
				gotchosen_experience_1: {
					...response.gotchosen_experience_1,
					user: this.modelFactory.userFactory.build(response.gotchosen_experience_1.user)
				},
				gotchosen_experience_2: {
					...response.gotchosen_experience_2,
					user: this.modelFactory.userFactory.build(response.gotchosen_experience_2.user)
				},
				gotchosen_experience_3: {
					...response.gotchosen_experience_3,
					user: this.modelFactory.userFactory.build(response.gotchosen_experience_3.user)
				}
			};
			
			console.log('wallet.service->getLastWinner(): winners', winners);
			
			return winners;
			
		}
		catch (e) {
			throw e;
		}
	}
	
}
