import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

@Directive({
  selector: 'img[mipLazyImg],[mipLazyImgBackground]'
})
export class LazyImgDirective implements OnInit, OnDestroy {

  static observer: IntersectionObserver = null;
  static assetsPath = environment.assetsPath;

  @Input() mipLazyImg = null;
  @Input() mipLazyImgBackground = null;

  constructor(private host: ElementRef) {
  }

  static intersectionObserver() {
    console.log('lazy-img.directive->intersectionObserver(): LazyImgDirective.observer', LazyImgDirective.observer);

    if (!LazyImgDirective.observer) {
      LazyImgDirective.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          const target = entry.target;
          const imgSrc = target.getAttribute('data-image-src');
          const imgBackground = target.getAttribute('data-image-background');

          if (!entry['_img-hold']) {
            entry['_img-hold'] = target.tagName === 'img' ? target : new Image();
            entry['_img-hold'].onload = () => {
              console.log('lazy-img.directive->intersectionObserver(): LOAD', target, entry);
              utilsFactory.removeClass(target, ['mip-lazy-img-loading']);
              entry['_img-hold'] = null;
            };
          }

          if (entry.isIntersecting) {
            if (imgSrc) {
              target['src'] = imgSrc;
            }
            else if (imgBackground) {
              entry['_img-hold'].src = imgBackground;
              target['style'].backgroundImage = `url('${imgBackground}')`;
            }

            console.log('lazy-img.directive->intersectionObserver(): LOAD', target, entry);

          }
          else {
            if (imgSrc) {
              target['src'] = null;
            }
            else if (imgBackground) {
              target['style'].backgroundImage = `none`;
            }

            console.log('lazy-img.directive->intersectionObserver(): UNLOAD', target, entry);

          }
        });
      });
    }
  }

  ngOnInit() {

    if (utilsFactory.isBrowser) {

      console.log('lazy-img.directive->constructor(): this.mipLazyImg', this.mipLazyImg);
      console.log('lazy-img.directive->constructor(): this.mipLazyImgBackground', this.mipLazyImgBackground);

      if (this.mipLazyImg) {
        this.host.nativeElement.setAttribute('data-image-src', this.mipLazyImg);
      }

      if (this.mipLazyImgBackground) {
        this.host.nativeElement.setAttribute('data-image-background', this.mipLazyImgBackground);
      }

      utilsFactory.addClass(this.host.nativeElement, 'mip-lazy-img-loading');

      LazyImgDirective.intersectionObserver();
      LazyImgDirective.observer.observe(this.host.nativeElement);

    }

  }

  ngOnDestroy() {
    if (LazyImgDirective.observer) {
      LazyImgDirective.observer.unobserve(this.host.nativeElement);
    }
  }

}
