import { EnvironmentInterface } from './environment.interface';
import { environment as prodEnv } from './environment.prod';

const settings: EnvironmentInterface = {
  environmentName: 'qa',
  networkUrl: 'https://qa2dyevy8xkexz.gotchosen.com',
  loginUrl: 'https://login-qa.gotchosen.com',
  collabUrl: 'https://collab-qa.gotchosen.com',
  appBaseHrefUseValue: '//qa2dyevy8xkexz.gotchosen.com',
  assetsPath: 'https://cdnqa.gotchosen.com/mip/ssr/assets',
  ssrBasePath: 'https://cdnqa.gotchosen.com/mip/ssr',
  replaceMediaUrl: {
    replace: 'https://d1tl9hf5y9d46i.cloudfront.net',
    with: 'https://dvb8a2zlmlge3.cloudfront.net'
  },
  originalLogs: false,
  disableLogs: false,
  disableLogsServer: false,
  newRelicAgentID: '1103130861',
  enableTestVideoAd: true,
  disableAds: false,
  adsPlacement: {
    disableWidgetAds: false,
    disableMobileTopAd: false,
    disableDesktopSideMenuAd: false,
    disablePostAd: false,
    disableFeedDetailsAd: false
  },
  apis: {
    api: 'https://king-kong-qa.gotchosen.com/api/v1.5',
    scraperApi: 'https://f0ronqpfu6.execute-api.us-east-1.amazonaws.com/qa',
    impressionsApi: 'https://influencers-api.gotchosen.com/api',
    impressionsApi2: 'https://events-centralizer-qa.gotchosen.com/v1',
    eventsApi: 'https://events-centralizer-qa.gotchosen.com/v1',
    cdn: 'https://cdn.gotchosen.com',
    mipWebApi: 'https://t8pslyoask.execute-api.us-east-1.amazonaws.com/qa',
    searchApi: 'https://king-kong-qa.gotchosen.com/api',
    backOfficeApi: 'https://king-kong-qa.gotchosen.com/backoffice/api',
    collabApi: 'https://analytics-api-kong-gateway.qa.gotchosen.net/collab',
    websocket: 'wss://qa-influencer-notifications.gotchosen.com/api'
  }
};

export const environment: EnvironmentInterface = { ...prodEnv, ...settings };
