import moment from 'moment-timezone';

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {
  AdvertisementService,
  MobileDetectService,
  PostsService,
  ReferralSectionFactory,
  ResourceService,
  RouterService,
  SideMenuService,
  UserService,
  utilsFactory,
} from '@lib/gc-common';
import { environment } from '@libs/gc-common/environments/environment';

@Component({
  selector: 'mip-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('networkContainer')
  networkContainer: ElementRef;
  @ViewChild('drawer')
  sidenav: MatSidenav;

  isBrowser = true;
  isDesktop = false;
  isMobile = true;
  isInstagramBrowser = false;
  networkContainerEl = null;

  onRouteChangeSubscription = null;

  isProduction = environment.environmentName === 'production';
  environmentName = environment.environmentName.toUpperCase();

  constructor(
    private sideMenuService: SideMenuService,
    private advertisementService: AdvertisementService,
    private routerService: RouterService,
    private mobileDetectService: MobileDetectService,
    private postService: PostsService,
    private resourceService: ResourceService,
    private userService: UserService,
  ) {
    // console.log('network.component->constructor()');

    this.isBrowser = utilsFactory.isBrowser;
    this.isMobile = this.mobileDetectService.isMobile();
    this.isDesktop = !this.mobileDetectService.isMobile();
    this.isInstagramBrowser = this.mobileDetectService.isBrowserName('instagram');
  }

  async ngOnInit() {
    // window.screen.width, window.screen.height

    const routeParams = await this.routerService.getRouteParam();
    // console.log('network.component->ngOnInit(): routeParams', routeParams);
    // console.log('network.component->ngOnInit(): this.advertisementService.isAdDisabled()', this.advertisementService.isAdDisabled());

    if (utilsFactory.isBrowser) {
      if (!this.advertisementService.isAdDisabled()) {
        if (routeParams.userId) {
          await this.advertisementService.registerUserSection(routeParams.userId, routeParams.postId);
        }

        this.onRouteChangeSubscription = this.routerService.onRouteChange().subscribe((route) => {
          // console.log('network.component->onRouteChange(): GA: ', route.url);

          // console.log('network.component->onRouteChange(): window.gtag: ', window['gtag']);
          // @ts-ignore
          window['gtag']('config', 'G-00FFKR0ET6', {
            page_path: route.url,
          });
        });

        /* GTAG */
        await utilsFactory.loadScript('https://www.googletagmanager.com/gtag/js?id=G-00FFKR0ET6');
        // window['gtag']('js', new Date());

        /* QUANTCAST */
        await utilsFactory.loadScript('https://cdn.gotchosen.com/libs/quantcast.js');
      }

      // console.log('network.component->ngOnInit(): document.referrer', document.referrer);

      if (routeParams.userId && routeParams.postId) {
        const referralSectionFactory = new ReferralSectionFactory(routeParams.userId);
        // console.log('network.component->ngOnInit(): referralSectionFactory', referralSectionFactory);

        if (referralSectionFactory.isActive && referralSectionFactory.hasRefresh === false) {
          const referralUserPost = await this.postService.getPost(routeParams.postId);
          // console.log('network.component->ngOnInit(): referralSectionFactory: referralUserPost', referralUserPost);

          const referralUser = referralUserPost.author;
          // console.log('network.component->ngOnInit(): referralSectionFactory: referralUser', referralUser);

          if (this.userService.isAuthenticated()) {
            this.userService.onUserAuthentication().subscribe(async (user) => {
              // console.log('network.component->ngOnInit(): referralSectionFactory: onUserAuthentication', user);

              await this.resourceService.post('eventsApi', {
                path: '/event',
                data: [
                  {
                    event: 'user.link-shared-back',
                    content: {
                      author: referralUser.id,
                      user: user.id,
                      timestamp: moment['tz']('America/New_York').format('YYYY-MM-DD HH:mm:ss.[000]'),
                    },
                  },
                ],
              });
            });
          } else {
            // console.log('network.component->ngOnInit(): referralSectionFactory: NOT AUTHENTICATED');

            await this.resourceService.post('eventsApi', {
              path: '/event',
              data: [
                {
                  event: 'user.link-shared-back',
                  content: {
                    author: referralUser.id,
                    timestamp: moment['tz']('America/New_York').format('YYYY-MM-DD HH:mm:ss.[000]'),
                  },
                },
              ],
            });
          }
        }
      }

      if (this.isDesktop && environment.enableWidget) {
        /* GCJS */
        // console.log('network.component->ngOnInit(): environment.gcjsUrl', environment.gcjsUrl);
        await utilsFactory.loadScript(`${environment.gcjsUrl}/GC_542da18fbd01b/gc.js`, () => {
          // console.log('network.component->ngOnInit(): window.GC', window['GC']);
          return window['GC'];
        });

        window['GC'].init('GC_542da18fbd01b', {
          show_advertisement_label: false,
          disable_ads: this.advertisementService.isAdDisabled('disableWidgetAds'),
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.onRouteChangeSubscription) {
      this.onRouteChangeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.sideMenuService.registerSidenav(this.sidenav);
    // console.log('network.component->ngAfterViewInit(): this.sidenav', this.sidenav);

    this.networkContainerEl = this.networkContainer.nativeElement;

    if (this.isInstagramBrowser && utilsFactory.isBrowser) {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      // console.log('network.component->ngAfterViewInit(): window.screen', width, height);
      // console.log('network.component->ngAfterViewInit(): window.inner', window.innerWidth, window.innerHeight);
      // console.log('network.component->ngAfterViewInit(): window.client', document.documentElement.clientWidth, document.documentElement.clientHeight);
      this.networkContainerEl.style.width = `${width}px`;
      this.networkContainerEl.style.height = `${height}px`;
    }
  }
}
