import {
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { ContestModel } from '@libs/gc-common/lib/api/models/contest';
import { HashtagModel } from '@libs/gc-common/lib/api/models/hashtag';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { ShareModalService } from '@libs/gc-common/lib/features/share-modal/share-modal.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { MomentService } from '@libs/gc-common/lib/services/moment/moment.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';

@Component({
  selector: 'mip-hashtag-info',
  templateUrl: './hashtag-info.component.html',
  styleUrls: ['./hashtag-info.component.scss']
})
export class HashtagInfoComponent implements OnInit, OnChanges {

  @Input() hashtagModel: HashtagModel = null;
  @Input() postsList: Array<PostModel> = [];
  @Input() baseRoute = null;
  @Input() showShareButton = false;
  @Input() seeMoreTextColor = 'primary';

  @Input() showContestStats = false;

  @Input() bdColor: string = null;
  @Input() bgColor: string = null;
  @Input() textColor: string = null;
  @Input() enableAvatarCrowns = false;
  @Input() enableSampleLabel = false;
  @Input() seeMoreLink: string = null;

  @Input() enableTimestamp = false;

  contestModel: ContestModel = null;
  contestEndDateFromNow = null;

  isMobile = false;
  isDesktop = false;

  constructor(
    private momentService: MomentService,
    private shareModalService: ShareModalService,
    private mobileDetect: MobileDetectService,
    private routerService: RouterService
  ) {
    this.isMobile = this.mobileDetect.isMobile();
    this.isDesktop = !this.mobileDetect.isMobile();
  }

  ngOnInit() {

    /*console.log('hashtag-info.component->ngOnInit() this.hashtagModel', this.hashtagModel);
     console.log('hashtag-info.component->ngOnInit() this.postsList', this.postsList);

     if (this.hashtagModel && this.hashtagModel.contest) {
     this.contestModel = this.hashtagModel.contest;
     console.log('hashtag-info.component->ngOnInit() this.contestModel', this.contestModel);

     this.momentService.moment(this.contestModel.endDate).subscribe(moment => {
     this.contestEndDateFromNow = moment.fromNow(true);
     console.log('hashtag-info.component->ngOnInit() this.contestEndDateFromNow', this.contestEndDateFromNow);
     });

     }*/
  }

  ngOnChanges() {

    console.log('hashtag-info.component->ngOnChanges() this.hashtagModel', this.hashtagModel);
    console.log('hashtag-info.component->ngOnInit() this.postsList', this.postsList);

    if (this.hashtagModel && this.hashtagModel.contest) {
      this.contestModel = this.hashtagModel.contest;
      console.log('hashtag-info.component->ngOnChanges() this.contestModel', this.contestModel);

      const endDate = this.momentService.single({ date: this.contestModel.endDate });

      this.contestEndDateFromNow = endDate.fromNow(true);
      console.log('hashtag-info.component->ngOnInit() this.contestEndDateFromNow', this.contestEndDateFromNow, endDate.format('LLL'));

    }

  }

  shareHashtag() {
    console.log('hashtag-info.component->shareHashtag(): getCurrentUrl()', `/hashtag/${this.hashtagModel.hashtag}`);

    this.shareModalService.shareOpen({
      url: `/hashtag/${this.hashtagModel.hashtag}`,
      title: this.hashtagModel.hashtag,
      text: this.hashtagModel.description
    });
  }

}
