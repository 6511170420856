import {
  Pipe,
  PipeTransform
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(html: string, nowrap: boolean = false) {
    console.log('safe-html.pipe->transform(): html', html);

    if (nowrap) {
      html = html.replace(/<br>|<br\/>/g, ' ');
    }

    const HTML = this.sanitizer.bypassSecurityTrustHtml(html);
    console.log('safe-html.pipe->transform(): HTML', HTML);

    return HTML;
  }

}
