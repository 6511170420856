import { Injectable } from '@angular/core';
import { HashtagModel } from '@libs/gc-common/lib/api/models/hashtag';
import { PaginationInterface } from '@libs/gc-common/lib/api/services/pagination.interface';
import { ModelFactoryService } from '@libs/gc-common/lib/services/model-factory/model-factory.service';
import { ResourceService } from '@libs/gc-common/lib/services/resource/resource.service';

@Injectable({
	providedIn: 'root'
})
export class HashtagService {
	
	static instance: HashtagService;
	
	constructor(
		private resourceService: ResourceService,
		private modelFactory: ModelFactoryService
	) {
		HashtagService.instance = this;
	}
	
	async getHashtagInfo(hashtag: string, fromCache = true): Promise<HashtagModel> {
		try {
			
			const response = await this.resourceService.get('api', {
				resource: 'hashtags',
				path: `/hashtags/${hashtag}`,
				fromCache
			});
			
			console.log('hashtag.service->getHashtagInfo(): response', response);
			
			return this.modelFactory.hashtagFactory.build(response);
			
		}
		catch (e) {
			console.error('hashtag.service->getHashtagInfo(): ERROR', e);
			throw e;
		}
	}
	
	async getAllHashtag(params?: { page?: number, limit?: number, posts?: boolean }): Promise<{ pagination: PaginationInterface, list: Array<HashtagModel> }> {
		try {
			
			const response: { _pagination, items } = await this.resourceService.get('api', {
				resource: 'hashtags',
				path: `/hashtags/page/all`,
				params
			});
			
			console.log('hashtag.service->getAllHashtag(): response', response);
			
			return {
				pagination: response._pagination,
				list: this.modelFactory.hashtagFactory.buildCollection(response.items)
			};
			
		}
		catch (e) {
			throw e;
		}
	}
	
}
