import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from '@libs/gc-common/environments/environment.prod';
import { UserModel } from '@libs/gc-common/lib/api/models/user/user.model';
import { UserService } from '@libs/gc-common/lib/api/services/user/user.service';
import { ContactPageComponent } from '@libs/gc-common/lib/features/contact-form/contact-page.component';
import { ObserverHelper } from '@libs/gc-common/lib/helpers/observer.helper';
import { I18nService } from '@libs/gc-common/lib/services/i18n/i18n.service';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';
import { RouterService } from '@libs/gc-common/lib/services/router/router.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';

import { SideMenuService } from './side-menu.service';

@Component({
	selector: 'mip-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
	
	@ViewChild('sideMenu') sideMenu: ElementRef;
	
	observerHelper = new ObserverHelper();
	
	languages: Array<{ key: string, isActive: boolean }> = null;
	isAuthenticated = false;
	activeLanguage = 'en';
	country = null;
	currentYear = new Date().getFullYear();
	
	disableAd = environment.adsPlacement.disableDesktopSideMenuAd;
	// deployVersion = environment.appVersion;
	
	isMobile = true;
	isDesktop = false;
	
	loggedInUser: UserModel = null;
	
	assetsPath = environment.assetsPath;
	
	currentUrl = null;
	
	constructor(
		private translateService: TranslateService,
		private sideMenuService: SideMenuService,
		private userService: UserService,
		private routerService: RouterService,
		private mobileDetectService: MobileDetectService,
		private i18nService: I18nService,
		private bottomSheet: MatBottomSheet,
		@Optional() @Inject(REQUEST) private request: any
	) {
		
		this.isMobile = this.mobileDetectService.isMobile();
		this.isDesktop = !this.mobileDetectService.isMobile();
		
		this.observerHelper.addSubscription(
			this.userService.onUserAuthentication().subscribe(user => {
				this.isAuthenticated = this.userService.isAuthenticated();
				this.loggedInUser = user;
				// console.log('side-menu.component->constructor() onUserAuthentication', this.isAuthenticated, user);
				this.onLanguageChange();
			})
		);
		
		let routerChangeTimeout = null;
		
		this.observerHelper.addSubscription(
			this.routerService.onRouteChange().subscribe(({ url, queryParams }) => {
				// console.log('side-menu.component->constructor() { url, queryParams }', { url, queryParams });
				
				this.currentUrl = url;
				// console.log('side-menu.component->constructor() this.currentUrl', this.currentUrl);
				
				if (routerChangeTimeout) {
					clearTimeout(routerChangeTimeout);
				}
				
				routerChangeTimeout = setTimeout(() => {
					if (queryParams.contact) {
						this.bottomSheet.open(ContactPageComponent, { data: { formName: queryParams.contact } });
					}
				}, 100);
				
			})
		);
		
	}
	
	async ngOnInit() {
		// console.log('side-menu.component->ngOnInit(): this.translateService', this.translateService);
		
		// Since the languages are added asynchronous (based on country), on i18n.module, we need to watch the changes (none-official)
		this.i18nService.onLangAdded().subscribe(() => {
			// console.log('side-menu.component->ngOnInit(): onLangAdded(): this.i18nService', this.i18nService);
			this.onLanguageChange();
		});
		
		// console.log('side-menu.component->ngOnInit(): environment', environment);
		
	}
	
	ngOnDestroy() {
		this.observerHelper.unsubscribeAll();
	}
	
	async chanceLanguage(language) {
		// console.info('[AKI] side-menu.component->chanceLanguage(): language', language);
		
		this.translateService.use(language.key);
		
		setTimeout(() => {
			// console.info('side-menu.component->chanceLanguage(): setTimeout()');
			this.onLanguageChange();
		}, 10);
	}
	
	async logout() {
		await this.userService.logout();
	}
	
	onLanguageChange() {
		this.languages = [];
		
		const languages = this.translateService.getLangs();
		// console.log('side-menu.component->onLanguageChange(): languages', languages);
		
		this.activeLanguage = this.translateService.currentLang;
		// console.log('side-menu.component->onLanguageChange(): this.activeLanguage', this.activeLanguage);
		
		this.country = this.i18nService.getUserCountry();
		
		languages.forEach(lang => {
			this.languages.push({
				key: lang,
				isActive: lang === this.activeLanguage
			});
		});
		
		// console.log('side-menu.component->onLanguageChange(): this.languages', this.activeLanguage, this.languages);
		
	}
	
	closeSideMenu() {
		
		// console.log('side-menu.component->closeSideMenu()');
		
		if (this.isMobile) {
			this.sideMenuService.close();
			// this.routerService.navigateToChild([], {menuOpen: null});
		}
	}
	
	onClosing() {
	
	}
	
	isRouteEnable(route: string | Array<string>): boolean {
		if (this.currentUrl) {
			if (Array.isArray(route)) {
				
				for (const i in route) {
					if (this.currentUrl.indexOf(`/${route[i]}`) === 0) {
						return true;
					}
				}
				
				return false;
				
			}
			else {
				return this.currentUrl.indexOf(`/${route}`) === 0;
			}
		}
	}
	
	openContactModal(formName) {
		// console.log('side-menu.component->openContactModal(): formName', formName);
		// this.bottomSheet.open(ContactPageComponent, { data: { formName: formName } });
		this.routerService.navigateTo('./', {
			queryParams: { contact: formName },
			queryParamsHandling: 'merge'
		});
	}
	
}
