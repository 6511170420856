import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@libs/gc-common/environments/environment';
import DebugFactory from '@libs/gc-common/lib/factories/debug.factory';
import { utilsFactory } from '@libs/gc-common/lib/factories/utils.factory';

import { AppModule } from './app/app.module';

DebugFactory.log('START [DebugFactory]');

console.log('main.ts: DISABLE LOGS:', {
  isBrowser: utilsFactory.isBrowser,
  disableLogs: environment.disableLogs,
  isSSR: utilsFactory.isSSR,
  disableLogsServer: environment.disableLogsServer,
});

if (utilsFactory.isBrowser) {
  if (window.localStorage.getItem('mip-force-enable-log') === 'true') {
    environment.disableLogs = false;
  }
  
  /*if (environment.disableLogs) {
   
   console.assert = () => {
   };
   console.clear = () => {
   };
   console.count = () => {
   };
   console.group = () => {
   };
   console.groupCollapsed = () => {
   };
   console.groupEnd = () => {
   };
   /!*console.info = () => {
   };*!/
   console.log = () => {
   };
   console.table = () => {
   };
   console.time = () => {
   };
   console.timeEnd = () => {
   };
   console.trace = () => {
   };
   console.warn = () => {
   };
   
   }*/
}

/**
 * String.prototype.replaceAll() polyfill
 * https://vanillajstoolkit.com/polyfills/stringreplaceall/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!('replaceAll' in String.prototype)) {
  String.prototype['replaceAll'] = function (str, newStr) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr);
    }
    
    // If a string
    return this.replace(new RegExp(str, 'g'), newStr);
  };
}

platformBrowserDynamic().bootstrapModule(AppModule).catch((err) => console.error(err));
