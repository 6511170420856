import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { environment } from '@libs/gc-common/environments/environment';
import { ContestModel } from '@libs/gc-common/lib/api/models/contest';
import { ContestWinnerModel } from '@libs/gc-common/lib/api/models/contest-winner';
import { HashtagModel } from '@libs/gc-common/lib/api/models/hashtag';
import { PostModel } from '@libs/gc-common/lib/api/models/post';
import { MobileDetectService } from '@libs/gc-common/lib/services/mobile-detect/mobile-detect.service';

@Component({
  selector: 'mip-hashtag-preview',
  templateUrl: './hashtag-preview.component.html',
  styleUrls: ['./hashtag-preview.component.scss']
})
export class HashtagPreviewComponent implements OnInit, OnDestroy {

  @Input() contestModel: ContestModel = null;
  @Input() hashtagModel: HashtagModel = null;
  @Input() postsList: Array<PostModel> = [];
  @Input() postsWinners: {
    winner: ContestWinnerModel,
    second: ContestWinnerModel,
    joker: ContestWinnerModel
  } = null;
  @Input() tileWidth: number = null;
  @Input() tileHeight: number = null;
  @Input() enableTimestamp = true;
  @Input() orientation = 'square';
  @Input() slideSpacing = 4;

  @Input() customTitle: string = null;

  @Input() icon = null;
  @Input() iconSize = '3x';
  @Input() iconSvg = null;
  @Input() iconPng = null;
  @Input() iconSolid = null;
  @Input() iconBold = null;

  assetsPath = environment.assetsPath;
  isDesktop = false;

  constructor(
    private mobileDetect: MobileDetectService
  ) {
    this.isDesktop = !this.mobileDetect.isMobile();
  }

  ngOnInit() {
    console.log('hashtag-preview.component->ngOnInit(): this.postsList.length', this.postsList.length);
  }

  ngOnDestroy() {
  }

}
