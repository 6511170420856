<div class="__deploy-version" [class.--hide]="isProduction" [class.--is-mobile]="isMobile">[{{ environmentName }}]<!-- build: {{ deployVersion }}--></div>

<div class="network" [class.--is-desktop]="isDesktop" [class.--is-mobile]="isMobile" [class.--is-instagram-browser]="isInstagramBrowser">
  <!-- DESKTOP TOP BAR -->
  <mip-top-menu-bar *ngIf="isDesktop"></mip-top-menu-bar>

  <!-- NETWORK CONTENT -->
  <div #networkContainer class="__container">
    <mat-drawer-container #drawerContainer [hasBackdrop]="!isDesktop">
      <!-- SIDE MENU -->
      <mat-drawer #drawer [mode]="isDesktop ? 'side' : 'push'" [position]="isDesktop ? 'start' : 'end'" [opened]="isDesktop">
        <mip-side-menu></mip-side-menu>
      </mat-drawer>

      <!-- PAGE CONTENT -->
      <mat-drawer-content>
        <div class="__drawer-content">
          <router-outlet></router-outlet>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
